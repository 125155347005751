import React, { PropsWithChildren, ReactElement, useRef, PointerEvent, useState, useEffect } from "react";
import { arrows, pinIcon } from "./../../../../shared/icons"
import styles from "./ClassPopup.module.scss"
import DisplayInfo from "./DisplayInfo"
import { Link } from "gatsby";
import { useEventListener } from "../../../../hooks/UseEventListener";
import { IClassData, IDayData } from "../DayInterfaces";
import WebAccountService from "../../../../services/WebAccountService";
import { IReserveModal } from "../..";

interface props {
    dayName: IDayData,
    monthName: IDayData,
    numberDate: string,
    classData: IClassData,
    hourSplitter: any,
    handleClass: any,
    spinner : (param:boolean)=> void,
    reserveModal : (param: IReserveModal )=> void,
}

const ClassPopup = (props: PropsWithChildren<props>): ReactElement => {


    const {
        className,
        instructor,
        location,
        startDate,
        studio,
        totalDuration,
        description,
        shortDescription,
        slug,
        classImg,
        businessUnitCode
    } = props.classData


    const {
        dayName,
        monthName,
        numberDate,
        hourSplitter,
        handleClass,
        classData,
        spinner,
        reserveModal
    } = props

    const popupRef = useRef<HTMLDivElement>(null as any)
    const [isLoggedIn, setLogged] = useState(false);

    let currentStartHour = new Date(startDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE })

    const handlePointerDown = (e: PointerEvent) => {
        const target = e.target as HTMLElement;
        if (!popupRef.current.contains(target)) {
            handleClass(null);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("jwt-token")) {
            setLogged(true);
        }
        else {
            setLogged(false);
        }
    }, []);


    const handleReserve = async () => {
        // -- Open spinner
        spinner(true);
        const response = await WebAccountService.registerClass({ eventInstanceId: classData.eventInstanceMosoId });
        // -- Close spinner
        spinner(false);
        if (response.success) {
            reserveModal({
                title : "Success",
                message : "You have been registered to the class successfully",
                registered : true
            });
        }
        else {
            reserveModal({
                title : "Error",
                message : response.message
            });
        }
        
    }

    useEventListener("pointerdown", handlePointerDown);

    return (
        <div className={styles.generalWrapper}>

            <div className={styles.popupWrapper} ref={popupRef}>
                <div className={styles.overflow}>
                    <div className={styles.imgHeader}>
                        <button className={styles.collapseBtn} onClick={() => { handleClass(null) }}>
                            {arrows.right}<p>Collapse</p>
                        </button>
                        {classData.reserved && <p className={styles.reservedText}>Reserved</p> }
                        <img src={classImg?.url ? classImg.url : "/assets/image/ClassPlaceHolder.jpg" } alt={classImg?.alt ? classImg.alt : "Class Room" } className={styles.backgroundImg}></img>
                    </div>
                    <div className={styles.bodyPopup}>
                        {className && <h3 className={styles.popupTitle}>{className} <span> {shortDescription} </span></h3>}
                        <DisplayInfo
                            day={dayName}
                            month={monthName}
                            numberDate={numberDate}
                            startHour={hourSplitter(currentStartHour)}
                            durationTime={`${totalDuration} min`}
                            location={location}
                            studio={studio}
                            instructor={instructor}
                        />
                        {(!classData.noFeeRequired || !isLoggedIn )&& <Link to={'/try-us'} state={{ classSelected : className, club: businessUnitCode, date:startDate }} className={styles.reserveButton}>Try class</Link>}
                        {classData.noFeeRequired && isLoggedIn && <button onClick={handleReserve} className={`${classData.reserved ? styles.disabled : "" } ${styles.reserveButton}`}>Reserve</button>}
                        {slug && <div className={styles.classAbout}>
                            {description && <>
                                <div className={styles.titleWrapper}>
                                    <span>{pinIcon}</span>
                                    <h4 className={styles.title}>About This Class</h4>
                                </div>
                                <p className={styles.description}> {description}</p>
                            </>}
                            <div className={styles.linkWrapper}>
                                <Link to={slug} className={styles.link}> Explore {className}  </Link>
                                <span> {arrows.right}</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    )
}


export default ClassPopup

